import React, { useEffect, useRef, useState } from 'react'
import { Flex, ConfirmModal } from '../../../Components'
import { MainDataSection } from './Sections/MainData'
import { checkInput, clientInputCheck, patchTraveller, getStateDefaultData, getCorrectState, patchClient, showError } from './Functions'
import { SenderSection } from './Sections/Sender'
import { ClientSection } from './Sections/Client'
import { Button } from '../../../Components/Common/Button'
import { FC } from '../../../Services'
import { useNavigate } from 'react-router-dom'
import { Text } from '../../../Components/Common/Text'
import { HoursDates } from './Sections/HoursDates'
import { Events } from './Sections/Events'
import { getEventsCount, getTotalPassengersCount } from './Sections/Functions'
import { minimumSecondsForSave } from '.'
import { useAuth } from '../../../Provider/AuthProvider'

export const NewForm = () => {
  const stateRef = useRef()
  const [render, setRender] = useState(false)
  const [sender, setSender] = useState({ name: '', surname: '', email: '', phone: '' })
  const [agency, setAgency] = useState({ id: '', codClienteNerone: '', ragSociale: '' })
  const [client, setClient] = useState({ id: '', codCliente: '', ragSociale: '' })
  const [selectedTravellerId, setSelectedTravellerId] = useState('')
  const [cellulare, setCellulare] = useState('')
  const [prefix, setPrefix] = useState('')
  const [checkError, setCheckError] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startDate] = useState(new Date())
  const { user = {} } = useAuth()
  const { user: userObj = {} } = user || {}
  const { id, role } = userObj
  const [title, setTitle] = useState('')
  const [createdAt] = useState(new Date())

  const navigate = useNavigate()

  useEffect(() => {
    setState(getStateDefaultData({ }), true)
  }, [])

  const setState = (newState, forceRender) => {
    const mustRender = [
      getEventsCount(newState) !== getEventsCount(stateRef.current),
      getTotalPassengersCount(newState) !== getTotalPassengersCount(stateRef.current),
      forceRender
    ]?.some((v) => v)
    stateRef.current = newState
    mustRender && setRender(!render)
  }

  const handleNewClient = async () => {
    const newClient = await FC.service('clients').create({
      ...client,
      environmentId: agency.id
    })
    setClient(newClient)
    setModalVisible(false)
    const newSender = { ...sender, phone: prefix + cellulare }
    setLoading(true)
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: 'createConversationFromForm',
      userId: id,
      newConversation: true,
      userPhone: prefix + cellulare,
      ...(selectedTravellerId ? { travellerId: selectedTravellerId } : { }),
      ...stateRef.current
    })
    const conversationId = res?.conversationData?.conversationId
    await FC.service('conversations').patch(conversationId, { createdAt, title })
    const conversation = await FC.service('info').get('getConversationOperatore', { query: { conversationId } })
    await patchTraveller(conversation, newSender, newClient.id)
    window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Dati salvati con successo' })
    setLoading(false)
    navigate('/operatore/' + res?.conversationData?.conversationId)
  }

  const saveData = async () => {
    const newSender = { ...sender, phone: prefix + cellulare, prefix, cellulare }
    setCheckError(true)
    if (!checkInput(newSender) || !clientInputCheck(agency, client)) return
    setCheckError(false)
    if (!client.id && (client.codCliente || client.ragSociale)) {
      setModalVisible(true)
      return
    }
    if (!title) return showError('title')
    if (window.document.getElementById('gigiForm').getElementsByClassName('p-invalid').length > 0) return showError('campiObbligatori')
    const correctState = { ...getCorrectState(stateRef.current), sentFromGigiForm: true }
    setSender(newSender)
    setLoading(true)
    const res = await FC.service('public').create({
      type: 'testUnit',
      unit: 'createConversationFromForm',
      userId: id,
      newConversation: true,
      userPhone: prefix + cellulare,
      ...(selectedTravellerId ? { travellerId: selectedTravellerId } : { }),
      ...correctState
    })
    const conversationId = res?.conversationData?.conversationId
    await FC.service('conversations').patch(conversationId, { title, createdAt })
    const conversation = await FC.service('info').get('getConversationOperatore', { query: { conversationId } })
    await patchTraveller(conversation, newSender, client.id)
    await patchClient(client)
    window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Dati salvati con successo' })
    if ((new Date() - startDate) / 1000 > minimumSecondsForSave && (role !== 'admin' || process.env.NODE_ENV === 'development')) {
      FC.service('time').create({ userId: id, conversationId, createdAt: startDate, endAt: new Date(), time: new Date() - startDate })
    }
    setLoading(false)
    navigate('/operatore/' + res?.conversationData?.conversationId)
  }

  const stateProps = { state: [stateRef.current, setState], client: [client] }

  return (
    <Flex fw id='gigiForm'>
      <Text title bold value='CARICAMENTO ATTIVITÀ' style={{ marginRight: 20, marginBottom: 10 }} size={20} />
      <ClientSection client={[client, setClient]} agency={[agency, setAgency]} checkError={checkError} />
      <SenderSection
        selectedTravellerId={[selectedTravellerId, setSelectedTravellerId]}
        sender={[sender, setSender]} checkError={checkError} showSuggestions
        prefix={[prefix, setPrefix]} cellulare={[cellulare, setCellulare]}
        client={[client, setClient]} agency={[agency, setAgency]}
      />
      <HoursDates createdAt={createdAt} />
      <MainDataSection {...stateProps} title={[title, setTitle]} />
      <Events {...stateProps} />
      <ConfirmModal header='Conferma Cliente' label='Il cliente non è presente, lo vuoi inserire?' onConfirm={handleNewClient} visible={[modalVisible, setModalVisible]} />
      <Flex row style={{ marginTop: 20 }}>
        <Button icon='fa-duotone fa-floppy-disk' label='Salva' onClick={saveData} style={{ marginRight: 20 }} spinner={loading} />
      </Flex>
    </Flex>
  )
}
