import { FC, invalidateQuery } from '../../../Services'
import { useCQuery } from '../../../Services/QueryCache'
import prefixJson from './countriesPrefixPriority.json'
import { cap } from '../../../State'
import { queryClient } from '../../../App'

/**
 * Legge una chiave specifica da un oggetto JSON.
 *
 * @param {Object} json - L'oggetto JSON dal quale leggere il valore.
 * @param {string} location - Il percorso della chiave in formato stringa,
 *                            separato da punti (es. "chiave1.chiave2.chiave3").
 * @param {number} count - Un indice numerico che può sostituire il segnaposto #COUNT nel percorso.
 * @returns {*} - Il valore ottenuto seguendo il percorso delle chiavi nell'oggetto JSON.
 */
export const readKey = (json, location, count, count2) => { // funzione per leggere una chiave di un json
  const chiavi = location.split('.') // splitto la location per ottenere le chiavi
  let valore = json // inizializzo il valore con il json
  for (const chiave of chiavi) valore = valore?.[chiave === '#COUNT2' ? count2 : chiave === '#COUNT' ? count : chiave] // per ogni chiave, se è #COUNT prendo il count, altrimenti prendo la chiave
  return valore // ritorno il valore
}

/**
 * Controlla che i dati del mittente siano completi e che tutti i passeggeri abbiano nome e cognome.
 *
 * @param {Object} sender - L'oggetto che rappresenta il mittente, con le proprietà `name`, `surname`, `email`, `phone`.
 * @param {Array} passeggeri - Un array di oggetti passeggero, ciascuno con un `nome` e un `cognome`.
 *
 * @returns {boolean} - Ritorna `true` se tutti i controlli sono validi, altrimenti chiama `showError()` con l'appropriato messaggio di errore.
 */
export const checkInput = (sender, passeggeri) => {
  // rimosso controllo sul sender per permettere creazione attività anche senza dati mittente
  // if (!sender.name) return showError('name')
  // if (!sender.surname) return showError('surname')
  if (!sender.prefix && sender.phone) return showError('prefix')
  return true
}

/**
 * Scrive un valore in una specifica posizione all'interno di un oggetto JSON.
 *
 * @param {Object} json Oggetto JSON in cui scrivere il valore.
 * @param {string} location Percorso della chiave in formato stringa, separato da punti.
 *                          Utilizza '#COUNT' per utilizzare il valore del parametro 'count'.
 * @param {number} count Contatore utilizzato per sostituire '#COUNT' nella chiave.
 * @param {*} valore Il valore da assegnare alla chiave specificata.
 * @return {Object} L'oggetto JSON aggiornato se la chiave esiste, altrimenti restituisce un oggetto vuoto.
 */
export const writeKey = (json, location, count, count2, valore) => { // funzione per scrivere una chiave di un json
  const chiavi = location.split('.') // splitto la location per ottenere le chiavi
  let puntatore = json // inizializzo il puntatore con il json
  for (let i = 0; i < chiavi.length - 1; i++) puntatore = puntatore?.[chiavi[i] === '#COUNT2' ? count2 : chiavi[i] === '#COUNT' ? count : chiavi[i]] // per ogni chiave, se è #COUNT prendo il count, altrimenti prendo la chiave
  if (puntatore) {
    puntatore[chiavi[chiavi.length - 1]] = valore // scrivo il valore
    return json
  }
  return {}
}

/**
 * Ottiene lo stato corretto.
 * Questa funzione prende un oggetto di stato e restituisce una nuova
 * istanza di quest'ultimo con le stesse proprietà, escludendo quelle non necessarie.
 *
 * @param {Object} state - L'oggetto di stato originale da elaborare.
 * @returns {Object} Un nuovo oggetto di stato contenente solo le proprietà rilevanti.
 */
export const getCorrectState = (state) => {
  const {
    ...rest
  } = state
  return {
    ...rest
  }
}

/**
 * Crea un nuovo processo asincrono.
 *
 * @param {Object} state - Lo stato corrente da utilizzare nella creazione del processo.
 * @param {string} conversationId - L'identificativo della conversazione associata al processo.
 * @param {string} unitName - Il nome dell'unità per il processo.
 * @returns {Promise<string|null>} Ritorna l'ID del processo creato oppure `null` in caso di errore.
 */
export const createNewProcess = async (state, conversationId, unitName) => {
  try {
    const { id: processId } = await FC.service('info').create({ action: 'createNewProcess', state, conversationId, unitName })
    return processId
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore conferma form' })
    return null
  }
}

/**
 * Ritorna i dati di default per uno stato dato.
 *
 * Questa funzione prende un oggetto stato e ne ritorna una versione estesa con i dati di default.
 * Se `mainData` è presente nello stato, verrà preservato; altrimenti, verrà inizializzato come un oggetto vuoto.
 *
 * @param {Object} state - L'oggetto stato che può contenere vari campi, tra cui `mainData`.
 * @returns {Object} Un oggetto stato esteso con `mainData` di default se non era precedentemente definito.
 */
export const getStateDefaultData = (state) => ({
  mainData: {
    pnr: '',
    codiceEvento: '',
    ...state?.mainData || {}
  },
  ...state
})

/**
 * Ottiene il numero dei passeggeri da un oggetto JSON.
 *
 * @param {object} json - L'oggetto JSON contenente l'array dei passeggeri.
 * @returns {number} Il numero di passeggeri, oppure 0 se non sono presenti.
 */
export const getTravellerCount = (json) => json?.passeggeri?.length || 0

/**
 * Ottiene gli elementi raggruppati per servizio.
 *
 * Questa funzione restituisce un oggetto con tre proprietà: sender, travellers e mainData,
 * ciascuna contenente un oggetto che mappa id di elementi ai rispettivi dati.
 * Per ogni proprietà, viene eseguito un reduce sull'array corrispondente per trasformare l'array in un oggetto.
 *
 * @returns {Object} Oggetto con le seguenti proprietà:
 *                   - sender: Oggetto con id sender come chiavi e i relativi campi come valori.
 *                   - travellers: Oggetto con id travellers come chiavi e i relativi campi come valori.
 *                   - mainData: Oggetto con id dati principali come chiavi e i relativi campi come valori.
 */
// group by section dynamically
export const useElementsByService = () => {
  const { data = [] } = useCQuery(['formFields'])
  return data.reduce((acc, { id, section, ...otherFields }) => {
    if (!acc[section]) acc[section] = {}
    acc[section][id] = { id, ...otherFields }
    return acc
  }, {})
}

/**
 * Segna come letti i messaggi di una conversazione, specificatamente i messaggi inviati da 'traveller' che non sono ancora stati letti.
 *
 * @param {Object} conversation - L'oggetto conversazione contenente i messaggi da segnare come letti.
 * @returns {Promise<void>} Una promessa che si risolve quando tutti i messaggi sono stati segnati come letti.
 */
export const markMessagesAsRead = async (conversation) => {
  const messagesToRead = conversation?.messages?.filter((m) => !m.read && m.sender === 'traveller')
  if (!messagesToRead?.length) return
  await Promise.all(messagesToRead.map(({ id }) => FC.service('messages').patch(id, { read: true })))
  const assignedFilter = cap.assignedFilter.state
  const selectedFilter = cap.selectedFilter.state
  const range1 = cap.range.state[0].toLocaleDateString('it-IT')
  const range2 = cap.range.state[1].toLocaleDateString('it-IT')
  const queryArgs = ['conversations', assignedFilter, selectedFilter, { $in: [true, false] }, [range1, range2], null, true]
  const queryState = queryClient.getQueryState(queryArgs)
  if (queryState.isInvalidated) return
  queryClient.setQueryData(queryArgs, (oldConvData) => {
    if (!oldConvData) return oldConvData
    const newOldConvData = oldConvData.pages.map((page) => {
      return page.map((conv) => {
        if (conv.id === conversation.id) return { ...conv, messagesToRead: 0 }
        return conv
      })
    })
    return { ...oldConvData, pages: newOldConvData }
  })
}

/**
 * Trasforma l'array JSON di prefissi in un elenco di opzioni per una tendina.
 * Ogni elemento dell'array risultante contiene etichetta, prefisso, valore e priorità.
 *
 * @param {Array} prefixJson - Un array di oggetti con informazioni sui paesi e i loro codici di chiamata.
 * @return {Array} Ritorna un array di oggetti dove ogni oggetto rappresenta un'opzione della tendina.
 */
export const countryListDropDefault = prefixJson.map((elem, index) => ({
  label: `+${elem.dialCode} (${elem.name})`,
  prefix: elem.dialCode,
  value: elem.dialCode,
  priority: elem.priority
}))

/**
 * Ottiene il numero di telefono corretto in base ai prefissi definiti.
 * Questa funzione cerca all'interno di una lista predefinita per trovare un prefisso corrispondente.
 * Se viene trovato un prefisso, divide il numero di telefono in due parti:
 * la prima parte è il prefisso stesso, la seconda è il resto del numero.
 * Il separatore utilizzato per dividere le due parti è un punto.
 *
 * @param {string} phone - Il numero di telefono da processare.
 * @returns {string} Il numero di telefono diviso in prefisso e cellulare se viene trovato un prefisso,
 * altrimenti ritorna il numero intero non modificato.
 */
export const getCorrectPhone = (phone) => {
  let maxLength = 0
  // Troviamo la lunghezza massima dei prefissi per ottimizzare il ciclo
  countryListDropDefault.forEach(item => {
    if (item.prefix.length > maxLength) maxLength = item.prefix.length
  })

  for (let i = 1; i <= maxLength; i++) {
    const prefix = phone.substring(0, i)
    const filt = countryListDropDefault.find((item) => item.prefix === prefix && item.priority === 0)
    if (filt) {
      // Utilizziamo solo la prima parte del numero per evitare sostituzioni multiple
      return phone.substring(0, i) + '.' + phone.substring(i)
    }
  }
  // Ritorniamo il numero senza modifiche se non troviamo un prefisso corrispondente
  return phone
}

/**
 * Crea un nuovo cliente e lo associa ad una agenzia.
 *
 * @param {Object} conversation Oggetto della conversazione corrente.
 * @param {Object} sender Oggetto del mittente.
 * @param {Object} client Oggetto contenente i dettagli del cliente.
 * @param {Object} agency Oggetto contenente i dettagli dell'agenzia.
 * @returns {Promise<void>} Una promessa che non restituisce nulla se va a buon fine,
 *                          o mostra un messaggio di errore in caso di fallimento.
 * @async
 */
export const createNewClient = async (conversation, sender, { id, ...client }, agency) => {
  try {
    const newClient = await FC.service('clients').create({
      ...client,
      environmentId: agency.id
    })
    const newClientId = newClient.id
    await patchTraveller(conversation, sender, newClientId)
    window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Salvataggio effettuato con successo' })
    return newClient
  } catch (e) {
    return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore durante il salvataggio' })
  }
}

/**
 * Controlla che tutti i campi necessari per l'agenzia e il cliente siano inseriti.
 *
 * @param {Object} agency - Oggetto contenente i dati dell'agenzia.
 * @param {Object} agency.ragSociale - La ragione sociale dell'agenzia.
 * @param {Object} agency.codClienteNerone - Il codice dell'agenzia.
 * @param {Object} client - Oggetto contenente i dati del cliente.
 * @param {Object} client.ragSociale - La ragione sociale del cliente.
 * @param {Object} client.codCliente - Il codice del cliente.
 * @returns {boolean} Ritorna true se tutti i campi sono presenti, altrimenti chiama la funzione showError e ritorna il suo risultato.
 */
export const clientInputCheck = (agency, client) => {
  if (!agency.agencyName || !agency.codClienteNerone) return showError('missAgenzia')
  if (!client.ragSociale) return showError('ragSocialeCliente')
  return true
}

export const checkDuplicateEvents = (state) => {
  const events = (state?.events || [])?.map((event) => event.codiceEvento + event.pnr)
  const uniqueEvents = new Set(events)
  if (uniqueEvents.size !== events.length) return showError('duplicateEvents')
  return true
}

/**
 * Mostra un messaggio di errore specifico in base al campo passato.
 *
 * @param {string} field - Il campo per il quale mostrare il messaggio di errore.
 */
export const showError = (field) => {
  const messages = {
    ragSocialeCliente: 'Inserire la ragione sociale del cliente',
    codCliente: 'Inserire il codice cliente',
    missAgenzia: 'Inserire l\'agenzia',
    name: 'Inserire il nome del mittente',
    surname: 'Inserire il cognome del mittente',
    email: 'Inserire l\'email del mittente',
    phone: 'Inserire il telefono del mittente',
    prefix: 'Inserire il prefisso del telefono del mittente',
    passengerData: 'Inserire tutti i dati per i passeggeri',
    duplicateEvents: 'Non è possibile inserire due eventi con lo stesso codice evento e PNR',
    missingEvents: 'Inserire almeno un evento',
    title: 'Inserire il titolo',
    campiObbligatori: 'Inserire tutti i campi obbligatori'
  }

  const detail = `${messages[field]}`
  window.growl.show({ severity: 'error', summary: 'Errore', detail })
}

/**
 * Aggiorna le informazioni di un viaggiatore in una conversazione.
 *
 * @async
 * @function patchTraveller
 * @param {object} conversation - Oggetto della conversazione contenente informazioni del viaggiatore.
 * @param {object} sender - Oggetto contenente le informazioni da aggiornare per il viaggiatore.
 * @param {string} clientId - Identificativo univoco del cliente, se disponibile.
 * @returns {Promise<void>} Restituisce una promessa che si risolve una volta che i dati del viaggiatore sono stati aggiornati.
 */
export const patchTraveller = async (conversation, sender, clientId) => {
  await FC.service('travellers').patch(conversation.traveller.id, {
    ...sender,
    ...(clientId ? { clientId } : {})
  })
  if ((conversation.traveller.clientId || '') !== clientId) {
    invalidateQuery([['conversations'], ['conversation', conversation?.id?.toString()]])
  }
  if ((conversation.traveller.phone || '') !== sender.phone) {
    invalidateQuery([['conversation', conversation?.id?.toString()]])
  }
}

export const patchClient = async (client) => {
  await FC.service('clients').patch(client.id, {
    info: client?.info || '',
    pagamento: client?.pagamento || ''
  })
}

/**
 * Aggiorna i dettagli dell'agenzia.
 *
 * @param {object} conversation - Oggetto conversazione contenente le informazioni del viaggiatore e dell'ambiente cliente.
 * @param {function} setAgency - Funzione per aggiornare lo stato dell'agenzia con i nuovi dettagli.
 */
export const updateAgencyDetails = (conversation, setAgency) => {
  const { environment = {} } = conversation?.traveller?.client || {}
  const { environment: defaultPhoneEnvironment = {} } = conversation?.whatsappPhoneNumber || {}
  const { id: defaultId = '', codClienteNerone: defaultCodClienteNerone = '', agencyName: defaultAgencyName = '' } = defaultPhoneEnvironment || {}
  const agencyJson = {
    ...environment,
    id: environment?.id || defaultId,
    codClienteNerone: environment?.codClienteNerone || defaultCodClienteNerone,
    agencyName: environment?.agencyName || defaultAgencyName
  }
  setAgency(agencyJson)
}

/**
 * Aggiorna i dettagli del cliente con le informazioni ottenute dall'oggetto conversation.
 * Se una determinata informazione non è disponibile, per i campi 'codCliente' e 'ragSociale'
 * verrà impostata una stringa vuota, mentre il campo 'id' può rimanere undefined.
 *
 * @param {Object} conversation - L'oggetto conversazione da cui estrarre i dettagli del cliente.
 * @param {Function} setClient - La funzione di callback per impostare i dati del cliente.
 */
export const updateClientDetails = (conversation, setClient) => {
  const { client = {} } = conversation?.traveller || {}
  if (client !== null) setClient(client)
}

/**
 * Aggiorna i dettagli del mittente con le informazioni ottenute dall'oggetto conversation.
 * Se una determinata informazione non è disponibile, verrà impostata una stringa vuota.
 *
 * @param {Object} conversation - L'oggetto conversazione da cui estrarre i dettagli del mittente.
 * @param {Function} setSender - La funzione di callback per impostare i dati del mittente.
 */
export const updateSenderDetails = (conversation, setSender) => {
  const senderJson = {
    name: conversation?.traveller?.name || '',
    surname: conversation?.traveller?.surname || '',
    email: conversation?.traveller?.email || '',
    phone: conversation?.traveller?.phone || ''
  }
  setSender(senderJson)
}

/**
 * Aggiorna il prefisso e il cellulare del numero di telefono del mittente.
 *
 *
 * @param {Object} conversation - L'oggetto conversazione contenente le informazioni del viaggiatore.
 * @param {Function} setCellulare - La funzione di callback per impostare il cellulare del mittente.
 * @param {Function} setPrefix - La funzione di callback per impostare il prefisso del mittente.
 */
export const updatePhonePrefix = (conversation, setCellulare, setPrefix) => {
  const telefono = getCorrectPhone(conversation?.traveller?.phone || '')
  const [extractedPrefix, extractedCellulare] = telefono.split('.')
  setPrefix(countryListDropDefault.find((elem) => elem.prefix === extractedPrefix)?.value || '')
  setCellulare(extractedCellulare || '')
}

/**
 * Ottiene l'etichetta del mittente.
 * @param {*} sender
 * @param {*} travellerPhone
 * @param {*} user
 * @returns
 */
export const getSenderLabel = (sender, travellerPhone, user = {}) => {
  if (sender === 'traveller') return travellerPhone
  if (sender === 'operator') return user?.username || 'Operatore H24'
  return 'H24'
}

/**
 * Recupera l'ultimo passo di un processo dato il suo identificativo.
 * Interroga il servizio `processStep` per ottenere tutti i passi del processo e ritorna l'ultimo elemento dell'array.
 *
 * @async
 * @function getLastProcessStep
 * @param {string} processId - L'identificativo del processo da cui recuperare l'ultimo passo.
 * @returns {Promise<Object>} Promessa che si risolve con l'ultimo passo del processo se trovato.
 * @throws {Error} Lancia un'eccezione se fallisce la chiamata al servizio.
 */
export const getLastProcessStep = async (processId) => {
  const processSteps = await FC.service('processStep').find({ query: { processId } })
  return processSteps[processSteps.length - 1]
}

/**
 * Imposta il riassunto e rende visibile il modale delle note.
 *
 * @param {string} notes - Il testo delle note da impostare come riassunto.
 * @param {Function} setResume - Funzione per impostare il riassunto.
 * @param {Function} setNoteModalVisible - Funzione per cambiare la visibilità del modale delle note.
 */
export const setAndShowResume = (notes, setResume, setNoteModalVisible) => {
  setResume(notes)
  setNoteModalVisible(true)
  invalidateQuery(['conversation', 'conversations'])
}
